import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { isDesktop } from 'react-device-detect';
import { MAX_WIDTH } from '../../Utils/enums';


const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 50,
  height: 2,
  backgroundColor: '#000000',
  borderRadius: 3,
  margin: '0.8em auto'
}));

function BottomSheet(props) {
  const { window, children, open, setOpen } = props;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        // swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: false,
        }}
        className="hide-scrollbar"
        sx={isDesktop ? {
          position: 'fixed',
          left: '50%',
          transform: 'translate(-52%)',
          width: MAX_WIDTH
        } : {}}
      >

        <Puller />
        {children}
      </SwipeableDrawer>
    </Root>
  );
}

export default BottomSheet;
