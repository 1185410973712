import { Button, Card, Grid, ListItemText, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RoomSvg } from '../../Assets/room.svg';
import { getCredentials, getWallet } from '../../Config/api';
import { UIStore } from '../../Config/store';
import { addTime, displayTime } from '../../Helper/helpers';


export default function Home() {
  const navigate = useNavigate()
  const currentUserData = UIStore.useState(s => s.currentUserData) ?? {}
  const sampleData = [
    { name: 'Guest Name', value: currentUserData?.name },
    { name: 'Check In', value: displayTime(new Date(), 'LL') },
    { name: 'Check Out', value: addTime(new Date(),  [2, 'd'], 'LL') },
    { name: 'Reservation ID', value: '234348923749' },
    { name: 'PNR', value: '0063583453' },
  ]

  useEffect(() => {
    getWallet()
      .then(() => {
        getCredentials()
      })
  }, [])

  return (
    <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ padding: '1em' }}>

      <RoomSvg />

      <Card variant="outlined" sx={{ margin: '1em 0', padding: '0 1em 1em' }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="space-between"
          sx={{ padding: '1em 0em' }}
        >
          <Grid item xs={8}>
            <ListItemText
              primary="ITC Executive Club"
              primaryTypographyProps={{
                sx: {
                  fontSize: '1.5rem',
                  fontWeight: 500,
                  margin: 0
                }
              }}
              secondary="ITC Executive Club Exclusive Double"
              secondaryTypographyProps={{
                sx: {
                  fontSize: '1rem',
                  color: '#000000',
                  fontWeight: 'bold',
                  margin: 0
                }
              }} />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'right' }}>

            <a style={{ color: '#000000', fontSize: '0.8rem' }}
              href="/">
              View all details {">"}</a>
          </Grid></Grid>

        <Grid
          container direction="row" justifyContent="space-between" alignItems="flex-start">
          {sampleData.map((item, i) => <Grid key={"sample-" + i}
            item xs={12} sm={12} md={i % 2 === 0 ? 6 : 5} lg={i % 2 === 0 ? 5 : 6} sx={{
              textAlign: 'left',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >

            <Grid item xs={5} sm={5} md={5} lg={5} sx={{ textAlign: 'left' }}>
              <Typography variant="body1" sx={{ color: '#666666', overflowWrap: 'anywhere' }}>{item.name}</Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Typography variant="body1">:</Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', overflowWrap: 'anywhere' }}>{item.value ? item.value : null}</Typography>
            </Grid>
          </Grid>)}
        </Grid>

      </Card>
      <Button variant="contained" color="brand"
        sx={{ position: 'fixed', bottom: '8px', left: 'auto', width: '96%', margin: 'auto' }}
        onClick={() => navigate('/checkin')}>Continue to Pre Check In</Button>
    </Grid>
  )
}
