import axios from "axios";
import qs from 'qs';
import { axiosBaseInstance } from "../Config/axios";
import { routes } from "../Config/routes";
import { fetchFromStore, login, logout } from "../Config/store";

export const getAccessToken = () => {
  // console.log('fetchFromStore(authRes)', fetchFromStore('authRes'))
  // return fetchFromStore('authRes').accessToken;
  return localStorage.getItem('token') ?? fetchFromStore('authRes').accessToken
};

export const decodeAccessToken = (token) => {
  return new Promise((resolve, reject) => {
    resolve({})
  });
};

export const decodeAccessTokenAndCheckIfItIsAllowed = async (token) => {
  const decoded = await decodeAccessToken(token)
  console.log('decoded access token1', decoded);

  if (process.env.REACT_APP_ORG_ID_WHITELIST?.split(',').includes(decoded.clientId)) {
    console.log('true')
    return true;
  } else {
    console.log('false')
    throw new Error('This organization is not authorized to access this portal');
  }
};

export const generateLoginToken = async (email, password) => {
  const data = qs.stringify({
    username: email,
    grant_type: "password",
    password: password,
    client_id: "indisi-users",
  });

  const config = {
    method: "post",
    url: process.env.REACT_APP_AUTH_TOKEN_URL,
    headers: {
    },
    data: data,
  };

  try {
    const response = await axios(config);
    // await decodeAccessTokenAndCheckIfItIsAllowed(response.data.access_token)
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    refreshAccessTokenAtRegularInterval();
    return response.data;
  } catch (e) {
    console.log('error in login', e)
    if (e?.response?.data?.error_description || e?.message) {
      window.snackbar('error', e?.response?.data?.error_description ?? e?.message)
    }
    return false;
  }
};

export const generateLoginTokenFromRefreshToken = async (refreshToken) => {

  try {
    const response = await axios.put(process.env.REACT_APP_BASE_URL + routes.refreshToken, {}, {
      headers: {
        'Authorization': `Bearer ${refreshToken}`,
      }
    });
    login(response.data.data)
    return response;
  } catch (e) {
    console.log("Error==>", JSON.stringify(e));
    if (e.code === "ECONNABORTED") {
      return false;
    }
    // window?.dispatch(logout())
    return "LOGOUT";
  }
};

window.intervalId = null;
export const refreshAccessTokenAtRegularInterval = async () => {
  try {
    console.log('refresh hit')

    const authRes = fetchFromStore('authRes');
    window.intervalId = setInterval(async () => {
      if (authRes?.refreshToken) {
        await generateLoginTokenFromRefreshToken(authRes.refreshToken);
      }
    }, authRes?.expiresIn * 1000);
  } catch (e) {
    console.error(e);
  }
};

export const startRefreshAccessTokenAtRegularInterval = async () => {

  try {
    const authRes = fetchFromStore('authRes');
    console.log('authRes', authRes);
    if (authRes?.refreshToken) {
      const res = await generateLoginTokenFromRefreshToken(authRes.refreshToken);
      if (res.status === 200) {
        // await decodeAccessTokenAndCheckIfItIsAllowed(res.data.access_token)
        refreshAccessTokenAtRegularInterval();
        return true;
      } else if (res === "LOGOUT") {
        logout()
        return false;
        // window.open('/user/login', '_self');
      }
      return true;
    } else {
      logout()
      return false
    }
  } catch (e) {
    console.log('error in startRefreshAccessTokenAtRegularInterval', e)
    if (e?.response?.data?.error_description || e?.message) {
      window.snackbar('error', e?.response?.data?.error_description ?? e?.message)
    }
    // window.dispatch(logout())
    return false;
  }
};
