import { Button, CircularProgress, Grid } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FullScreenDialog from '../../Components/UI/fullScreenDialog'
import WebcamCapture from '../../Components/Webcam'
import { uploadDocGetAttributes } from '../../Config/api'
import { UIStore } from '../../Config/store'
import { MAX_WIDTH } from '../../Utils/enums'


const aspectRatios = {
    "Passport": 4 / 3,
    "Voter ID Card": 3 / 4
}

export default function Scan() {
    const navigate = useNavigate()
    const { state } = useLocation();
    const title = state?.title ?? "Passport"
    const docVal = state?.docVal ?? "passport"
    const flipButtonRef = useRef(null)
    const webCamCaptureRef = useRef(null)
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(true);
    return (
        <FullScreenDialog
            open={open}
            setOpen={() => {
                setOpen(false)
                navigate(-1)
            }}
            title={title}
            sx={{ maxHeight: '100vh', background: '#000000', height: '100%', }}
        >
            <Grid sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                background: '#000000', height: '100%',
            }}>
                {image ? <img src={image} alt="captured" style={{ width: '100%', maxWidth: MAX_WIDTH + 'px', margin: '1em auto' }} /> :

                    <WebcamCapture
                        enable={!image}
                        setImage={setImage}
                        buttonRef={webCamCaptureRef}
                        flipButtonRef={flipButtonRef}
                        aspectRatio={aspectRatios[title]} />}

                {image ? <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        position: 'absolute', bottom: '5px', width: '95%', margin: '0 8px',
                    }}>
                    <Button variant="outlined" color="brand"
                        sx={{ width: '45%' }}
                        onClick={async () => {
                            setImage(null)

                        }}>Retake</Button>
                    <Button
                        autoFocus={false}
                        variant="outlined"
                        color="brand"
                        sx={{ width: '45%' }}
                        onClick={async () => {
                            setLoading(true)
                            let attributes = await uploadDocGetAttributes(image, docVal)
                            let obj = {}
                            if (attributes) {

                                attributes?.forEach((x) => {
                                    obj[x.name] = x.value
                                })

                                UIStore.update(s => {
                                    s.docData = {
                                        docVal: docVal,
                                        documentName: title,
                                        image: image,
                                        ...obj
                                    }
                                    s.selectedCredentialAttributes = attributes
                                })
                                navigate('/checkin/displayInfo')
                            }
                            setLoading(false)
                        }}>Proceed</Button>
                </Grid> : <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        position: 'absolute', bottom: '5px', width: '95%', margin: '0 8px',
                    }}>
                    <Button variant="outlined" color="brand"
                        sx={{ width: '45%', }}
                        onClick={() => {
                            flipButtonRef.current.click()
                        }}>Flip Camera
                    </Button>
                    <Button variant="contained" color="brand"
                        sx={{ width: '45%', }}
                        onClick={async () => {
                            webCamCaptureRef.current.click()

                        }}>Capture</Button>
                </Grid>}
            </Grid>

            {loading ? <CircularProgress sx={{ position: 'absolute', top: "48%", left: '48%' }}
                color="brand" /> : null}
        </FullScreenDialog>
    )
}
