import { Close } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { UIStore, fetchFromStore, rehydrateStore } from './Config/store';
import Auth from './Containers/Auth';
import Base, { SecondaryTopBar } from './Containers/Base';
import Checkin from './Containers/Checkin';
import CheckedIn from './Containers/Checkin/checkedIn';
import Digilocker from './Containers/Checkin/digilocker';
import DisplayInfo from './Containers/Checkin/displayInfo';
import Payment from './Containers/Checkin/payment';
import Scan from './Containers/Checkin/scan';
import Home from './Containers/Home';
import Liveness from './Containers/Liveness';
import { MAX_WIDTH } from './Utils/enums';
import { startRefreshAccessTokenAtRegularInterval } from './Utils/tokenUtils';

function App() {
  const isAuthenticated = UIStore.useState(s => s.isAuthenticated);

  const [paymentDone, setPaymentDone] = useState(false)
  useEffect(() => {

    var r = document.querySelector(':root');
    r.style.setProperty('--MAX_WIDTH', MAX_WIDTH + "px");

    rehydrateStore()
    window.snackbar = (variant, msg) => {
      //console.log(msg);
      if (msg) {
        console.info('in snackbar')
        const key = enqueueSnackbar((
          <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: '100%', }}>
            <Grid item xs={11} sm={11} md={11} lg={11} sx={{ width: '100%', }}>
              <Typography variant="body1"
                sx={{ width: '100%', position: 'relative' }}>
                {msg?.slice(0, 1).toUpperCase().concat(msg?.slice(1))}
              </Typography>
            </Grid>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
              sx={{ position: 'absolute', top: '0px', right: '0px' }}
            >
              <Close fontSize="small" />
            </IconButton>


          </Grid>),
          {
            variant,
            style: { overflowWrap: "anywhere", zIndex: "9999999 !important" },
            className: "snackbar",
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            // action: (key) => (
            //   <IconButton
            //     sx={{ display: 'none' }}
            //     size="small"
            //     aria-label="close"
            //     color="inherit"
            //     onClick={() => closeSnackbar(key)}
            //   >
            //     <Close fontSize="small" />
            //   </IconButton>
            // ),
          }
        );
      }
    };
    console.log('fetchFromStore(isAuthenticated)', fetchFromStore('isAuthenticated'))
    if (fetchFromStore('isAuthenticated')) {
      startRefreshAccessTokenAtRegularInterval()
    }
    // updateStore('credentialLivenessCheckRecord', {})

  }, [])

  console.log('isAuthenticated', isAuthenticated)
  return (
    <BrowserRouter>
      <Base>
        <Routes>{
          isAuthenticated ? (
            <>
              <Route exact path="/checkin/digilocker" element={<Digilocker />} />
              <Route exact path="/checkin/scan" element={<Scan />} />
              <Route exact path="/checkin/displayInfo" element={<DisplayInfo />} />
              <Route exact path="/checkedIn/payment" element={<Payment paymentDone={paymentDone} setPaymentDone={setPaymentDone} />} />
              <Route exact path="/checkedIn" element={<CheckedIn setPaymentDone={setPaymentDone} />} />

              <Route exact path="/liveness" element={<Liveness />} />

              <Route element={<SecondaryTopBar />}>
                <Route exact path="/checkin" element={<Checkin />} />
                <Route path="*" element={<Home />} />
              </Route>
            </>
          ) : (
            <Route element={<SecondaryTopBar />}>
              <Route index path="*" element={<Auth />} />
            </Route>
          )
        }</Routes>
      </Base>
    </BrowserRouter>
  );
}

export default App;
