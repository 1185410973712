import { Check } from '@mui/icons-material';
import { Box, Button, Card, Chip, Divider, Grid, Link, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BrandLogo } from '../../Assets/brandLogo.svg';
import brandLogoWhite from '../../Assets/brandLogoWhite.svg';
import { UIStore } from '../../Config/store';
import AddOffers from './addOffers';
import { MAX_WIDTH } from '../../Utils/enums';
import { isDesktop } from 'react-device-detect';

const upgrades = [
    {
        id: 'up1',
        type: 'ROOM',
        image: 'https://storage.googleapis.com/indisi-images-dev/7ed1c40e2f7879b790f8e74e23d61abd4c5d8d7fcc55b83b4bece77042df699a',
        title: 'ITC One Double',
        subtitle: 'Upgrade at ₹2000',
        moat: 'You SAVE ₹5000 on this offer',

        caption: 'Upgrade your ITC One stay to Towers Suites for only ₹5000 more & enjoy free amenities',
        subtitle2: '1 King  |  65 m² square meters',
        subtitle3: 'Pure air | Sustainable in-room amenities | Luxury hours |Artisnal water | Spacious room | Flat Screen TV | 4 fixture bathroom | ',
        offer: '10% off',
        pay1: 5000,
        pay2: '₹18,500',
        pay3: '₹23,600',
    },
    {
        id: 'up2',
        type: 'ROOM',
        image: 'https://storage.googleapis.com/indisi-images-dev/0a33cba78dd5c372fc5b39c9e2a416198f5567676c2f948a25a9a0354c9d914a',
        title: 'ITC Towers Double',
        subtitle: 'Upgrade at ₹3000',
        moat: 'Best Value Deal',
        caption: 'Upgrade your ITC One stay to Towers Suites for only ₹3000 more & enjoy free amenities',
        subtitle2: '1 King  |  65 m² square meters',
        subtitle3: 'Pure air | Sustainable in-room amenities | Luxury hours |Artisnal water | Spacious room | Flat Screen TV | 4 fixture bathroom | ',
        offer: '10% off',
        pay1: 3000,
        pay2: '₹14,500',
        pay3: '₹20,600',
    }, {
        id: 'up3',
        type: 'ROOM',
        image: 'https://storage.googleapis.com/indisi-images-dev/4002464a240b7a774690627a36ae7738d09594137fea140c5b857ef162ced156',
        title: 'ITC Suites Double',
        subtitle: 'Upgrade at ₹5000',
        moat: '',
        caption: 'Upgrade your ITC One stay to Towers Suites for only ₹5000 more & enjoy free amenities',
        subtitle2: '1 King  |  65 m² square meters',
        subtitle3: 'Pure air | Sustainable in-room amenities | Luxury hours |Artisnal water | Spacious room | Flat Screen TV | 4 fixture bathroom | ',
        offer: '10% off',
        pay1: 5000,
        pay2: '₹20,500',
        pay3: '₹30,600',
    }
]
const exclusiveDeals = [
    {
        id: 'exd1',
        image: 'https://storage.googleapis.com/indisi-images-dev/a392342a0dcf073adbfcff1ad37478e10a278e4670a15bfc59eda65e9a5a194a',
        title: 'Breakfast in Bed',
        subtitle: 'Save 20%',
        caption: 'Upgrade your ITC One stay to discounted breakfast',
        subtitle2: 'Bacon And Caramelised Onion Grilled Cheese Sandwich',
        subtitle3: "Subko's signature Sourdough layered with house made Bacon Onion Jam, Rocket Leaves and Cheese, and grilled.",
        offer: '30% off',
        pay1: 1000,
        pay2: '₹1,500',
        pay3: '₹2,600',
    },
    {
        id: 'exd2',
        image: 'https://storage.googleapis.com/indisi-images-dev/1e975f71e788ff81baf04e7d4551a26661d71edc67ab1bc5370ff1c9d47d27f8',
        title: 'Spa @ Kaya Kalp',
        subtitle: '₹1,500',
        subtitle1: (<span style={{ fontWeight: 500, fontSize: '1rem' }}>
            <s style={{ color: '#000000' }}>₹2,500</s>
            &nbsp;
            <span style={{ color: 'red', fontWeight: 500, }}>40% off</span>
        </span>),
        caption: 'Relax to Beethovens Music',
        subtitle2: 'Experience the best of professional wellness at TATTVA Spa.',
        subtitle3: "Massages, Beauty Elixir, Express Therapies",
        offer: '30% off',
        pay1: 1000,
        pay2: '₹1,500',
        pay3: '₹2,600',
    }, {
        id: 'exd3',
        image: 'https://storage.googleapis.com/indisi-images-dev/1a0b47cebb8f23b9dc0ec143a8916a20e04e552b9713cfc8ff35cab3b94d0ff5',
        title: 'Pickup & Drop',
        subtitle: 'Flat 15% off',
        moat: '',
        caption: 'Go Anywhere',
        subtitle2: 'We have a fleet of different-sized, modern, A/C vehicles waiting to take you anywhere. All cars are highly maintained, with a clean and safe in-car environment.',
        subtitle3: "Office Cab Service, Airport & Railway Stations Pickup & drop",
        offer: '30% off',
        pay1: 1000,
        pay2: '₹1,500',
        pay3: '₹2,600',
    },
    {
        id: 'exd4',
        image: 'https://storage.googleapis.com/indisi-images-dev/bdeca2a2aabf548ab13d3210eec921b02b04837077de5fb746f69c8219d6df18',
        title: 'Souvenir Shop',
        subtitle: 'Save 10%',
        moat: '',
        caption: 'Discover a New India',
        subtitle2: 'Add elegance and charm to your home with these beautiful masterpieces',
        subtitle3: "Brass Sculptures, Clay Pots",
        offer: '30% off',
        pay1: 2000,
        pay2: '₹2,500',
        pay3: '₹3,600',
    }, {
        id: 'exd5',
        image: 'https://storage.googleapis.com/indisi-images-dev/bcc0f2aa2cfa8e6bd940437b6b181cf5eab103bf541460102abcd433e24e1cb0',
        title: 'Conference Room',
        subtitle: 'Book and get 20%',
        moat: '',
        caption: 'Every unique business finds a home and work doesn’t feel like work',
        subtitle2: 'Whether you’re a startup or a Fortune 500 company, we offer workspace solutions tailored to your needs.',
        subtitle3: "Custom-built offices, Flexible Workspaces, Meeting Rooms",
        offer: '30% off',
        pay1: 2000,
        pay2: '₹2,500',
        pay3: '₹3,600',
    }
]

const comboDeals = [
    {
        id: 'cd1',
        image: 'https://storage.googleapis.com/indisi-images-dev/d704a60a5321d7405d59c8021c8cfe16fa36bbfff79d2575996bb21218bec0f8',
        title: '3 Course Meal',
        subtitle: 'Flat 20% off',
        moat: 'Book Now>',
        theme: '#685841',
        caption: 'Corporate Thali (Veg)',
        subtitle2: '100% Desi Ghee',
        subtitle3: "Shahi Paneer + Kadai Paneer + Dal Makhani + Salad + Roti/Naan + Rice + Raita + Pickle + Dessert",
        offer: '30% off',
        pay1: 2000,
        pay2: '₹2,500',
        pay3: '₹3,600',
    },
    {
        id: 'cd2',
        image: 'https://storage.googleapis.com/indisi-images-dev/e8d2ee376386982f02a7ada72fa734ce27062cdab626c0a48af01ce39ea4146d',
        title: 'Buy 1 Get 1',
        subtitle: 'On Beer Buckets @ ₹3250',
        moat: 'Book Now>',
        theme: '#531E30',
        caption: 'Beer Buckets',
        subtitle2: 'Ice Bucket for beer, wine, champagne, cans, cold drinks and more.',
        subtitle3: "Kingfisher, Tuborg, Carlsberg, Budweiser, Heineken, Corona, Bira 91, Foster's Lager, Hoegaarden, MillerCoors",
        offer: '30% off',
        pay1: 20000,
        pay2: '₹2,5000',
        pay3: '₹3,6000',
    }
]
const whatAreYouLookingFor = [
    {
        id: 'waylf1',
        image: 'https://storage.googleapis.com/indisi-images-dev/a8191492acd13f2b9e7118c46a45926781dda16ec06fa82d898e8c63fe3bf635',
        title: 'Kaya Kalp-The Spa',
    },
    {
        id: 'waylf2',
        image: 'https://storage.googleapis.com/indisi-images-dev/dac8d33b150c5bec31b4d0c2813847562286c5a0c0fac0e31af6f1b65da09bd1',
        title: 'Fitness Centre',
    },
    {
        id: 'waylf3',
        image: 'https://storage.googleapis.com/indisi-images-dev/4133d3e1f933b60748619db79a54b343fd448ef4644d60b688416983993ef57a',
        title: 'City Getaways',
    }]

const whatAreYouLookingFor2 = [{

    id: 'waylf4',
    image: 'https://storage.googleapis.com/indisi-images-dev/26c5c364f9e2629de25b21b32f0668bd9fa7a6822b2e03ec588415ac65b1c29d',
    title: 'Swimming Pool',
}, {
    id: 'waylf5',
    image: 'https://storage.googleapis.com/indisi-images-dev/f26fca81b8bfec88711c9f484288cc89a6c9fd2f803172a74986cdba3ca91ed1',
    title: 'Radiation Harmonization',
},
{
    id: 'waylf6',
    image: 'https://storage.googleapis.com/indisi-images-dev/e9dc3c6f9aad0ccf1700a1caf5f3254d0a0986c2796c7a2fed512c0bb44796a7',
    title: 'Laundry',
}
]
export default function CheckedIn({ setPaymentDone }) {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [loaded, setLoaded] = React.useState(true);

    const selectedOffers = UIStore.useState(s => s.selectedOffers)
    return (
        <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ padding: '1em 0', height: '100%', maxWidth: MAX_WIDTH + 'px' }}>
            <Header />

            <Divider sx={{ width: '100%' }} />
            <Grid container direction="row" justifyContent="space-between" alignItems="center"
                sx={{ padding: '0 1em' }}>
                <Typography variant="h6" sx={{
                    textAlign: 'left', color: '#C39440'
                }}>Special Pre-Check In offers</Typography>
                <Link color="primary" onClick={async () => {
                    setPaymentDone("loading")
                    navigate('/checkedIn/payment')
                }}
                    sx={{ fontWeight: 600, cursor: 'pointer' }}>Skip{">"}</Link>
            </Grid>

            <Box sx={{ backgroundColor: '#F5F5F5', width: '100%' }}>
                <Typography variant="h6" sx={{
                    textAlign: 'center',
                    fontWeight: 400, letter: '10%',
                    lineHeight: '14.18px',
                    width: '100%', padding: '0 1em', color: '#4E4E4E'
                }}>UPGRADE YOUR HOTEL ROOM</Typography>

                <Grid container direction="row" sx={{
                    maxWidth: '100%', flexWrap: 'nowrap',
                    minHeight: '200px',
                    overflow: 'auto',
                }}
                    className="hide-scrollbar">

                    {upgrades.map((x, i) => <Card className='easeload' key={x.id}
                        sx={{
                            border: selectedOffers[x.id] ? '1px solid #BD550A' : '',
                            borderRadius: '15px',
                            minWidth: '200px',
                            margin: '1em',
                            // display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            position: 'relative',
                        }}
                        onClick={() => {
                            setOpen(x)
                        }}>

                        {selectedOffers[x.id] ? <Chip sx={{
                            position: 'absolute', top: '10px',
                            left: '10px', color: '#ffffff', background: '#BD550A'
                        }} icon={<Check />}
                            label="Selected"
                            color="primary" /> : null}

                        <img loading='lazy' src={x.image} alt={x.title}
                            style={{ maxHeight: '200px', width: '100%' }}
                            onLoad={() => setLoaded(false)} />
                        <ListItemText
                            sx={{ margin: '0.6em' }}
                            primary={x.title}
                            secondary={x.subtitle}
                            primaryTypographyProps={{
                                sx: {
                                    fontSize: '1rem',
                                    fontWeight: 700,
                                    margin: 0,
                                }
                            }}
                            secondaryTypographyProps={{
                                sx: {
                                    fontSize: '1rem',
                                    fontWeight: 400,
                                    margin: 0,
                                    color: '#000000'
                                }
                            }}
                        />
                        {x.moat ? <Typography variant="body1" sx={{
                            textAlign: 'center',
                            width: '100%', padding: '0.6em 0.2em', backgroundColor: '#C39440',
                            color: '#ffffff',
                            fontSize: '0.6rem'
                        }}>{x.moat}</Typography> : null}
                    </Card>)}
                </Grid>
            </Box>

            <Box sx={{ backgroundColor: '#F5F5F5', width: '100%' }}>
                <Typography variant="h6" sx={{
                    textAlign: 'center',
                    fontWeight: 400, letter: '10%',
                    lineHeight: '14.18px',
                    width: '100%', padding: '0 1em', color: '#4E4E4E'
                }}>EXCLUSIVE DEALS FOR YOU</Typography>

                <Grid container direction="row" sx={{
                    maxWidth: '100%', flexWrap: 'nowrap',
                    overflowX: 'auto', scroll: 'hidden',
                    minHeight: '200px',
                }}
                    className="hide-scrollbar">
                    {exclusiveDeals.map((x, i) => <Card className='easeload' key={x.id} sx={{
                        borderRadius: '15px',
                        minWidth: '200px',
                        margin: '1em',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        border: selectedOffers[x.id] ? '1px solid #BD550A' : '',
                        position: 'relative'
                    }}
                        onClick={() => {
                            setOpen(x)
                        }}>{selectedOffers[x.id] ? <Chip sx={{
                            position: 'absolute', top: '10px',
                            left: '10px', color: '#ffffff', background: '#BD550A'
                        }} icon={<Check />}
                            label="Selected"
                            color="primary" /> : null}
                        <img loading='lazy' src={x.image} alt={x.title}
                            style={{ maxHeight: '200px', width: '200px' }} />
                        <ListItemText
                            sx={{ margin: '0.6em' }}
                            primary={x.title}
                            secondary={<span>{x.subtitle}&nbsp;{x?.subtitle1}</span>}
                            primaryTypographyProps={{
                                sx: {
                                    fontSize: '1rem',
                                    fontWeight: 700,
                                    margin: 0,
                                }
                            }}
                            secondaryTypographyProps={{
                                color: 'brand',
                                sx: {
                                    fontSize: '1.2rem',
                                    fontWeight: 700,
                                    margin: 0,
                                    color: '#C39440'
                                }
                            }}
                        />
                        {x.moat ? <Typography variant="body1" sx={{
                            textAlign: 'center',
                            width: '100%', padding: '0.6em 0.2em', backgroundColor: '#C39440',
                            color: '#ffffff',
                            fontSize: '0.6rem'
                        }}>{x.moat}</Typography> : null}
                    </Card>)}
                </Grid>
            </Box>

            <Box sx={{ backgroundColor: '#F5F5F5', width: '100%' }}>
                <Typography variant="h6" sx={{
                    textAlign: 'center',
                    fontWeight: 400, letter: '10%',
                    lineHeight: '14.18px',
                    width: '100%', padding: '0 1em', color: '#4E4E4E'
                }}>COMBO DEALS</Typography>

                <Grid container direction="row" sx={{
                    maxWidth: '100%', flexWrap: 'nowrap',
                    overflowX: 'auto', scroll: 'hidden',
                    minHeight: '200px',
                }}
                    className="hide-scrollbar">
                    {comboDeals.map((x, i) => <Grid key={x.id}
                        className='easeload'
                        component={Card} sx={{
                            borderRadius: '15px',
                            minWidth: '300px',
                            maxHeight: '200px',
                            margin: '1em',
                            padding: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            border: selectedOffers[x.id] ? '1px solid #BD550A' : '',
                            position: 'relative'
                        }}
                        onClick={() => {
                            setOpen(x)
                        }}>{selectedOffers[x.id] ? <Chip sx={{
                            position: 'absolute', top: '10px',
                            left: '10px', color: '#ffffff', background: '#BD550A'
                        }} icon={<Check />}
                            label="Selected"
                            color="primary" /> : null}
                        <Grid item xs={6} sx={{
                            backgroundColor: x.theme, height: '100%',
                            padding: '1em'
                        }}>
                            <img loading='lazy' style={{ width: '40px', height: '40px' }} src={brandLogoWhite} alt="brand" />
                            <ListItemText
                                sx={{ margin: '0.6em' }}
                                primary={x.title}
                                secondary={<span>{x.subtitle}&nbsp;{x?.subtitle1}</span>}
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: '0.9rem',
                                        fontWeight: 800,
                                        margin: 0,
                                        color: '#ffffff'
                                    }
                                }}
                                secondaryTypographyProps={{
                                    color: 'brand',
                                    sx: {
                                        fontSize: '0.8rem',
                                        fontWeight: 500,
                                        margin: 0,
                                        color: '#ffffff'
                                    }
                                }}
                            />

                            {x.moat ? <Button variant="brand" sx={{
                                textAlign: 'center',
                                width: 'fit-content', padding: '0.6em 0.2em', backgroundColor: '#C39440',
                                color: '#ffffff',
                                fontSize: '0.6rem',
                            }}>{x.moat}</Button> : null}
                        </Grid>
                        <Grid item xs={6}>
                            <img loading='lazy' src={x.image} alt={x.title}
                                style={{ maxHeight: '200px', height: "100%", objectFit: "cover" }} />
                        </Grid>

                    </Grid>)}
                </Grid>
            </Box>

            <Box sx={{ backgroundColor: '#F5F5F5', width: '100%' }}>
                <Typography variant="h6" sx={{
                    textAlign: 'center',
                    fontWeight: 400, letter: '10%',
                    lineHeight: '14.18px',
                    width: '100%', padding: '0 1em', color: '#4E4E4E'
                }}>WHAT ARE YOU LOOKING FOR?</Typography>

                <Grid container direction="column"
                    justifyContent="center"
                    alignItems="center" sx={{
                        width: '100%', flexWrap: 'nowrap',
                        overflowX: 'auto', scroll: 'hidden'
                    }}
                    className="hide-scrollbar">
                    <Grid container direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            flexWrap: 'nowrap',
                            minHeight: '200px',
                        }} >
                        {whatAreYouLookingFor.map((x, i) => <Grid key={x.id}
                            className='easeload'
                            sx={{
                                borderRadius: '20px',
                                minWidth: '200px',
                                // maxHeight: '180px',
                                margin: '1em',
                                padding: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}><img loading='lazy' src={x.image} alt={x.title}
                                style={{ height: '100%', width: '100%', borderRadius: '20px', padding: 0 }} />

                            <Typography variant="h6" sx={{
                                textAlign: 'center',
                                fontWeight: 700,
                            }}>{x.title}</Typography>
                        </Grid>)}
                    </Grid>

                    <Grid container direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            flexWrap: 'nowrap',
                            minHeight: '200px',
                        }}>
                        {whatAreYouLookingFor2.map((x, i) => <Grid key={x.id}
                            className='easeload'
                            sx={{
                                borderRadius: '20px',
                                minWidth: '200px',
                                // maxHeight: '180px',
                                margin: '1em',
                                padding: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}><img loading='lazy' src={x.image} alt={x.title}
                                style={{ height: '100%', width: '100%' }} />

                            <Typography variant="h6" sx={{
                                textAlign: 'center',
                                fontWeight: 700,
                            }}>{x.title}</Typography>
                        </Grid>)}</Grid>

                </Grid>

                {
                    Object.keys(selectedOffers).length > 0 ? <>
                        <span>
                            <Button variant="contained" color="primary"
                                sx={{ width: '45%', visibility: 'hidden', margin: '1em' }}>.</Button>
                        </span>
                        <Grid container sx={{
                            ...(isDesktop ? {
                                position: 'fixed',
                                bottom: 0,
                                left: '50%',
                                transform: 'translate(-49.4%)',
                                width: MAX_WIDTH
                            } : {
                                position: 'fixed',
                                bottom: 0, left: 0,
                            }),
                            backgroundColor: '#ffffff'
                        }}>
                            <Divider sx={{ width: '100%' }} />
                            <Grid container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    padding: '1em'
                                }}>
                                <ListItemText
                                    sx={{ margin: '0.6em', marginRight: 'auto' }}
                                    primary="You Pay"
                                    secondary={"₹" + Object.values(selectedOffers).reduce((acc, z) => z.pay1 + acc, 0)}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontSize: '0.8rem',
                                            fontWeight: 700,
                                            margin: 0,
                                            color: '#444444',
                                            textAlign: 'left'
                                        }
                                    }}
                                    secondaryTypographyProps={{
                                        color: 'brand',
                                        sx: {
                                            fontSize: '1.2rem',
                                            fontWeight: 700,
                                            margin: 0,
                                            color: '#000000',
                                            textAlign: 'left'
                                        }
                                    }}
                                />
                                <Button variant="contained" color="primary"
                                    sx={{ width: '45%' }}
                                    onClick={async () => {
                                        navigate('/checkedIn/payment')
                                    }}>Pay Now</Button>
                            </Grid>
                        </Grid></>
                        : null
                }
            </Box>
            <AddOffers open={open} setOpen={setOpen}
                selectedOffers={selectedOffers} />
        </Grid>
    )
}

export function Header() {
    return <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1em' }}
    >
        <Grid item xs={4}>
            <a href="/"><BrandLogo /></a>
        </Grid>
        <Grid item xs={7}>
            <Typography variant="body1" sx={{ fontWeight: 700, textAlign: 'left', fontSize: '0.8rem' }}>
                ITC Maurya, a Luxury Collection Hotel, New Delhi
            </Typography>
        </Grid>
    </Grid>
}