import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#D3AC67',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#D3AC67',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        // borderTopWidth: 3,
        // borderRadius: 1,

        borderWidth: '2px',
        borderRadius: 0,
        position: 'relative',
        left: '-9px',
        borderLeftStyle: 'dotted',
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#D3AC67',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#D3AC67',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active: true }} className={className}>

            <div className="QontoStepIcon-circle" />
        </QontoStepIconRoot>
    );
}

export default function CustomStepper({ steps }) {
    return (
        <Stepper activeStep={1}
            orientation='vertical' connector={<QontoConnector />} sx={{ margin: '0 1em' }}>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel sx={{ padding: 0 }} StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>

    )
}
