import { isDesktop } from "react-device-detect";

export const getIdentifierKey = (key) => {
    switch (key) {
        case "Aadhaar": return 'Aadhaar ID';
        case "Aadhaar Card": return 'Aadhaar ID';
        case "Driving License": return 'License Number';
        case "Passport": return "documentNumber";
        case "Voter ID Card": return 'epicNumber';
        case "Voter ID": return 'epicNumber';
        default: return 'id'
    }
}
export const getNameKey = (key) => {
    switch (key) {
        case "Aadhaar": return 'Name';
        case "Aadhaar Card": return 'Name';
        case "Driving License": return 'Name';
        case "Passport": return "firstName";
        case "Voter ID Card": return 'fullName';
        case "Voter ID": return 'fullName';
        default: return 'name'
    }
}

export const LIVENESS_CONFIDENCE_SCORE = 50;
export const IMAGE_COMPARE_CONFIDENCE_SCORE = 70;

export const trentialVerifiedId = {
    "credentialDefinition": "VAzwPLN9qQDgtKbQiAiHJs:3:CL:5286:Unique",
    "credentialBatchId": "65425307d755afa5af9f52f2",
    "attributes": [
        {
            "name": "Full Name",
            "mime-type": "text/plain",
            "mapping": {
                "Aadhaar": "Name",
                "Aadhaar Card": "Name",
                "Driving License": "Name",
                Passport: "firstName",
                "Voter ID Card": "fullName",
                "Voter ID": "fullName"
            },
            "clientIdentifier": "name"
        },
        {
            "name": "Date Of Birth",
            "mime-type": "text/plain",
            "mapping": {
                "Aadhaar": "Date of Birth",
                "Aadhaar Card": "Date of Birth",
                "Driving License": "Date of Birth",
                Passport: "birthDate",
                "Voter ID Card": "birthYear",
                "Voter ID": "birthYear"
            },
            "clientIdentifier": "dob"
        },
        {
            "name": "Gender",
            "mime-type": "text/plain",
            "mapping": {
                "Aadhaar": "Gender",
                "Aadhaar Card": "Gender",
                "Driving License": "Gender",
                Passport: "sex",
                "Voter ID Card": "gender",
                "Voter ID": "gender"
            },
            "clientIdentifier": "gender"
        },
        // {
        //     "name": "Photo",
        //     "mime-type": "image/png",
        //     "clientIdentifier": "photo",
        //     "mapping": {
        //         "Aadhaar": "Photo",
        //         "Driving License": "Photo",
        //         Passport: "photo",
        //         "Voter ID Card": "photo",
        //         "Voter ID": "photo"
        //     }
        // }
    ]
}

export const MAX_WIDTH = isDesktop ? 420 : window.innerWidth;
export const MAX_WIDTH_HALF = isDesktop ? 200 : (window.innerWidth / 2) - 10;