import { AccessTime, Discount } from '@mui/icons-material'
import { Box, Button, Card, CardMedia, Chip, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import BottomSheet from '../../Components/UI/bottomSheet'
import { UIStore } from '../../Config/store'

export default function AddOffers({ open, setOpen, selectedOffers }) {
    const data = { ...open };
    return (
        <BottomSheet open={open} setOpen={setOpen}>
            <Grid container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                className="hide-scrollbar"
                sx={{ padding: '1em 0', height: '100%', flexWrap: 'nowrap' }}>
                <Card sx={{ width: '95%', borderRadius: '14px', position: 'relative' }}>
                    <CardMedia
                        component="img"
                        height="200"
                        width="100%"
                        image={data.image}
                        alt="add-offer"
                        sx={{ width: '100%' }}
                    />
                    <span style={{ padding: 0, height: '100%' }}>
                        <Typography variant="body1" sx={{
                            textAlign: 'left', backgroundColor: '#D3AC67',
                            padding: '2px 1em',
                            color: '#ffffff',
                            fontSize: '0.7rem',
                            height: '100%'
                        }}>{data.caption}</Typography>
                    </span>
                    <Chip sx={{ position: 'absolute', top: '10px', left: '10px' }} icon={<AccessTime />}
                        label="Expires in 15 min"
                        color="primary" />
                </Card>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexWrap: 'nowrap',
                    width: '100%',
                    padding: '1em',
                    overflowWrap: 'break-word'
                }}>
                    <Typography variant="h5" sx={{
                        textAlign: 'left',
                    }}>{data.title}</Typography>
                    <Typography variant="h6" sx={{
                        textAlign: 'left',
                    }}>{data.subtitle}</Typography>
                    <Typography variant="body1" sx={{
                        textAlign: 'left',
                        fontWeight: 500,
                        margin: '0.4em 0'
                    }}>{data.subtitle2}</Typography>
                    <Typography variant="body1" sx={{
                        textAlign: 'left',
                        fontWeight: 500,
                        margin: '0.4em 0'
                    }}>{data.subtitle3}</Typography>
                    <Chip icon={<Discount />}
                        label={data.offer}
                        color="brand"
                        sx={{ margin: '1em 0', marginRight: 'auto', fontSize: '1rem' }} />

                    <Typography variant="h5" sx={{
                        textAlign: 'left',
                        fontWeight: 600
                    }}>You pay just ₹{data.pay1}</Typography>
                    <Typography variant="body1" sx={{
                        textAlign: 'left',
                        fontSize: '1.2rem',
                        color: '#666666',
                        fontWeight: 500

                    }}>{data.pay2} &nbsp; <s style={{ color: '#000000', fontSize: '1rem' }}>{data.pay3}</s></Typography>

                    <Button
                        sx={{
                            marginRight: 'auto', fontSize: '1rem', width: 'fit-content',
                            padding: 0,
                            fontWeight: 500
                        }}>Price Details {">"}</Button>

                </Box>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                    padding: '1em'
                }}>
                <Button color="primary"
                    sx={{ width: '45%' }}
                    onClick={async () => {
                        setOpen(false)
                    }}>Cancel</Button>
                <Button variant={selectedOffers[data.id] ? 'outlined' : 'contained'} color="primary"
                    sx={{ width: '45%' }}
                    onClick={async () => {
                        if (selectedOffers[data.id]) {
                            let z = { ...selectedOffers }
                            delete z[data.id]
                            UIStore.update(s => {
                                s.selectedOffers = z;
                            })
                        } else {
                            let z = { ...selectedOffers }
                            z[data.id] = data
                            UIStore.update(s => {
                                s.selectedOffers = z;
                            })
                        }
                        setOpen(false)
                    }}>{selectedOffers[data.id] ? 'Remove' : 'Upgrade'}</Button>
            </Grid>
        </BottomSheet>
    )
}
