import { CameraAlt, CameraswitchOutlined } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React, { useRef } from "react";
import { Camera } from "react-camera-pro";
import { UIStore } from "../../Config/store";
import { MAX_WIDTH, MAX_WIDTH_HALF } from "../../Utils/enums";


const WebcamCapture = ({ setImage, buttonRef, flipButtonRef, aspectRatio }) => {
    const camViewRef = useRef(null)
    const webcamFacingMode = UIStore.useState(s => s.webcamFacingMode) ?? "user";

    // const [camView, setCamView] = useState({})


    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.takePhoto();
            setImage(imageSrc);
        },
        [setImage, webcamRef]
    );
    console.log(webcamFacingMode)

    // useEffect(() => {
    // const bbox = camViewRef.current.getBoundingClientRect()
    // setCamView(bbox)

    // }, [])

    return (
        <Grid container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                padding: '1em', width: '100%', maxWidth: MAX_WIDTH + 'px',
                background: '#000000', height: '100%', margin: 'auto',
            }}>

            <div ref={camViewRef} style={{
                maxWidth: MAX_WIDTH + 'px',
                border: '3.5px dashed #FFFFFF',
                width: '100%'
            }}>
                <Camera ref={webcamRef}
                    facingMode={webcamFacingMode}
                    aspectRatio={aspectRatio} />
            </div>
            {/* <img src={rectangle} alt="rectangle" style={{
                position: 'absolute',
                ...camView,
                zIndex: 9999
            }} /> */}
            <Button
                ref={buttonRef}
                variant='outlined'
                color="secondary"
                sx={{
                    display: 'none',
                    padding: '1em 2em',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: "45%",
                    maxWidth: MAX_WIDTH_HALF
                }}
                onClick={() => {
                    capture()
                }}>
                <CameraAlt />
                &nbsp;
                Capture
            </Button>

            <Button
                ref={flipButtonRef}
                variant='outlined'
                color="brand"
                sx={{
                    display: 'none',
                    padding: '1em 2em',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '5em',
                    right: '2em',
                    width: "45%",
                    maxWidth: MAX_WIDTH_HALF
                }}
                onClick={() => {

                    UIStore.update(s => {
                        s.webcamFacingMode = webcamFacingMode === 'user' ? 'environment' : 'user';
                    })
                    webcamRef.current.switchCamera()
                }}>
                <CameraswitchOutlined />
                &nbsp;
                Flip Camera
            </Button>
        </Grid>
    );
};

export default WebcamCapture