import axios from "axios";

const addInterceptors = (instance) => {

    // Add a request interceptor
    instance.interceptors.request.use(
        (config) => {
            // console.log("axios request");
            return config;
        },
        (error) => {
            console.error("Intercepting request error", error);
            window.snackbar("error", error.message)
            // return Promise.reject(error);
        }
    );

    // Add a response interceptor
    instance.interceptors.response.use(
        (response) => {
            if (response.status === 504) {
                window.snackbar("", "Server is down, please try again later");
            }

            if (response.status !== 200) {
            }
            return response;
        },
        (error) => {
            console.error("Intercepting outside 2xx responce error", error.message, error);
            if (error.response.data.code !== 104) {
                window.snackbar("error", error?.response?.data?.message ?? error?.message)
            }
            return Promise.reject(error);
        }
    );
};

const TIMEOUT_MILLISECONDS = 10 * 60 * 1000;

const baseURLs = {
    base: `${process.env.REACT_APP_BASE_URL}`,
    digilocker: `${process.env.REACT_APP_DIGILOCKER_URL}`,
    issuer: `${process.env.REACT_APP_ISSUER_TRENTIAL_URL}`
};

const axiosBaseInstance = axios.create({
    baseURL: baseURLs.base,
    timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptors(axiosBaseInstance);

const axiosDigilockerInstance = axios.create({
    baseURL: baseURLs.digilocker,
    timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptors(axiosDigilockerInstance);

const axiosIssuerTrentialInstance = axios.create({
    baseURL: baseURLs.issuer,
    timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptors(axiosIssuerTrentialInstance);

export {
    axiosBaseInstance,
    axiosDigilockerInstance,
    axiosIssuerTrentialInstance
};

