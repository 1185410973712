import { ArrowBack, ArrowForward, ArrowRight } from '@mui/icons-material'
import { Avatar, Backdrop, Button, Card, CircularProgress, Divider, FormControl, FormHelperText, Grid, IconButton, Link, OutlinedInput, Skeleton, Typography } from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import globe from '../../Assets/globe.svg'
import india from '../../Assets/india.svg'
import { aadhaarOtpCall, aadharCall, getCredentials, getWallet } from '../../Config/api'
import { UIStore, updateStore } from '../../Config/store'
// const getDots = (text) => {
//     let t = '';
//     for (let i = 4 - text.length; i > 0; i--) {
//         t += '•'
//     }
//     return t;
// }

export default function Checkin() {
    const navigate = useNavigate();
    const credentials = UIStore.useState(s => s.credentials)
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const submitOtpRef = useRef(null)
    const [currentScreen, setCurrentScreen] = useState('.')
    const [aadharNumber1, setAadharNumber1] = useState('')
    const [aadharNumber2, setAadharNumber2] = useState('')
    const [aadharNumber3, setAadharNumber3] = useState('')
    const [otpSent, setOtpSent] = useState(false)
    const [otpInput, setOtpInput] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setCurrentScreen('loading')
        getWallet().then(async z => {
            if (z) {
                getCredentials().then(res => {
                    if (res) {
                        setCurrentScreen('existingAccount')
                    } else {
                        setCurrentScreen('.')
                    }
                })
            }
        })

    }, [])


    const debouncedAadharCall = useCallback(debounce(async (val) => {
        setLoading(true)
        aadharCall(val)
            .then(x => {
                if (x) {
                    setOtpSent(x)
                }
            }).finally(() => {
                setLoading(false)
            })
    }, 1000), [aadharNumber1, aadharNumber2, aadharNumber3]);


    const screens = {
        '.': <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ padding: '1em', width: '100%' }}>
            <Typography variant="h6" sx={{ textAlign: 'left' }}>
                <IconButton
                    onClick={() => navigate('/')}
                ><ArrowBack /></IconButton>
                Select your Citizenship</Typography>
            <Button variant="outlined" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
                onClick={() => setCurrentScreen('in')}
            >
                <Avatar src={india} />
                Indian Citizen
                <span></span>
            </Button>
            <Button variant="outlined" sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
                onClick={() => setCurrentScreen('fo')}>
                <Avatar src={globe} />
                Foreign National
                <span></span>
            </Button>

        </Grid>,
        // 'in': <Grid container
        //     direction="column"
        //     justifyContent="center"
        //     alignItems="flex-start"
        //     sx={{ width: '100%' }}>
        //     <Typography variant="h6" sx={{
        //         textAlign: 'left',
        //         display: 'flex',
        //         flexDirection: 'row',
        //         justifyContent: 'flex-start',
        //         alignItems: 'center'
        //     }}><IconButton
        //         onClick={() => setCurrentScreen('.')}
        //     ><ArrowBack /></IconButton>Select Authentication Method</Typography>
        //     <Grid container sx={{ padding: '0 1em' }}>
        //         <Button variant="outlined"
        //             onClick={() => navigate('/checkin/digilocker')}>
        //             Fetch Via Digilocker
        //         </Button>
        //         <Divider variant="fullWidth" sx={{ width: '100%' }}>or</Divider>
        //         <Button variant="outlined"
        //             onClick={() => navigate('/checkin/scan', { state: { title: "Voter ID Card", docVal: 'voter-id' } })}>Upload Voter ID Card</Button>
        //         <Divider variant="fullWidth" sx={{ width: '100%' }}>or</Divider>
        //         <Button variant="outlined"
        //             onClick={() => navigate('/checkin/scan', { state: { title: "Passport", docVal: 'passport' } })}>Upload Passport</Button>
        //     </Grid>
        // </Grid>,
        'in': <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}><IconButton
                onClick={() => setCurrentScreen('.')}
            ><ArrowBack /></IconButton>Select Authentication Method</Typography>
            <Grid container sx={{ padding: '0 1em' }}>

                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%', marginBottom: '1em' }}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <OutlinedInput autoFocus
                            sx={{ padding: 0 }}
                            value={aadharNumber1}
                            inputProps={{
                                style: {
                                    padding: '8px 10px', color: aadharNumber1 ? '#000000' : '#B8B8B8', fontSize: '1rem',
                                    letterSpacing: aadharNumber1 ? '4px' : '10px',
                                    textAlign: 'center'
                                },
                                ref: ref1
                            }}
                            onChange={(e) => {
                                let t = e.target.value;
                                // t = t + getDots(e.target.value)

                                if (t.length >= 5) {
                                    setAadharNumber2(e.target.value.charAt(e.target.value.length - 1))
                                    ref2.current.focus()
                                } else {
                                    setAadharNumber1(t)
                                    if (t.length === 4) {
                                        ref2.current.focus()
                                    }
                                }
                            }}
                            placeholder='••••'
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <OutlinedInput
                            // ref={ref2}
                            sx={{ padding: 0 }}
                            value={aadharNumber2}
                            inputProps={{
                                style: {
                                    padding: '8px 10px', color: aadharNumber2 ? '#000000' : '#B8B8B8', fontSize: '1rem',
                                    letterSpacing: aadharNumber2 ? '4px' : '10px',
                                    textAlign: 'center'
                                },
                                ref: ref2
                            }}
                            onChange={(e) => {
                                let t = e.target.value;
                                // t = t + getDots(e.target.value)
                                if (t.length >= 5) {
                                    setAadharNumber3(e.target.value.charAt(e.target.value.length - 1))
                                    ref3.current.focus()
                                } else if (t.length === 0) {
                                    setAadharNumber2(t)
                                    ref1.current.focus()
                                } else {
                                    setAadharNumber2(t)
                                    if (t.length === 4) {
                                        ref3.current.focus()
                                    }
                                }
                            }}
                            placeholder='••••'
                        /></Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                        <OutlinedInput
                            sx={{ padding: 0 }}
                            value={aadharNumber3}
                            inputProps={{
                                style: {
                                    padding: '8px 10px', color: aadharNumber3 ? '#000000' : '#B8B8B8', fontSize: '1rem',
                                    letterSpacing: aadharNumber3 ? '4px' : '10px',
                                    textAlign: 'center'
                                },
                                ref: ref3
                            }}
                            onChange={(e) => {
                                let t = e.target.value;
                                // t = t + getDots(e.target.value)
                                if (t.length >= 5) {
                                    //hit api
                                    if (aadharNumber1.length >= 4 && aadharNumber2.length >= 4 && aadharNumber3.length >= 4) {

                                        // debouncedAadharCall()
                                    }
                                } else if (t.length === 0) {
                                    setAadharNumber3(t)
                                    ref2.current.focus()
                                } else {
                                    setAadharNumber3(t)
                                    if (t.length === 4) {
                                        //hit api
                                        if (aadharNumber1.length >= 4 && aadharNumber2.length >= 4) {
                                            debouncedAadharCall(aadharNumber1 + aadharNumber2 + t)
                                        }
                                    }
                                }
                            }}
                            placeholder='••••'
                        /></Grid>
                </Grid>

                {(otpSent) ?

                    <Grid container
                        direction="column"
                        justifyContent="center"
                        alignItems="center">
                        <Typography variant="body2" color="secondary"
                            sx={{ textAlign: 'center' }}>Enter the OTP recieved on your mobile
                        </Typography>
                        <FormControl>
                            <OutlinedInput
                                autoFocus
                                sx={{
                                    margin: "0.2em 0 0.2em"
                                }}
                                value={otpInput}
                                inputProps={{
                                    style: {
                                        padding: '8px 10px', color: aadharNumber2 ? '#000000' : '#B8B8B8', fontSize: '1rem',
                                        letterSpacing: aadharNumber2 ? '4px' : '10px',
                                        textAlign: 'center'
                                    }
                                }}
                                onChange={(e) => {
                                    setOtpInput(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        submitOtpRef.current.click()
                                    }
                                }}
                                placeholder='••••••'
                            />
                            <FormHelperText sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                margin: 0
                            }}>
                                <Button variant="text"
                                    onClick={(e) => { debouncedAadharCall(aadharNumber1 + aadharNumber2 + aadharNumber3) }}
                                    sx={{
                                        width: 'fit-content',
                                        margin: 0,
                                        padding: 0,
                                        fontWeight: 600,
                                        marginLeft: 'auto'
                                    }} >Resend OTP</Button>
                            </FormHelperText>
                        </FormControl>

                        <Button
                            ref={submitOtpRef}
                            variant="contained"
                            disabled={(otpInput.length === 0) || loading}
                            sx={{ margin: '1em 0em' }}
                            onClick={async () => {
                                setLoading(true)
                                await aadhaarOtpCall(otpSent, otpInput, navigate)
                                    .then(x => {
                                        if (x) {

                                        }
                                    })
                                setLoading(false)
                            }}>Submit</Button>
                    </Grid>
                    : null}

                <Typography variant="body1" sx={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '0.8rem'
                }}>Don’t remember your Aadhaar Number?</Typography>
                <Link color="primary"
                    sx={{
                        margin: '0.5em auto 1em', padding: 0, width: 'fit-content', cursor: 'pointer', fontWeight: 600,
                        fontSize: '0.8rem'
                    }}
                    onClick={() => navigate('/checkin/digilocker')}>
                    Verify using Digilocker
                </Link>


                <Divider variant="fullWidth" sx={{ width: '100%' }}>or</Divider>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}>
                    <Button variant="outlined"
                        onClick={() => navigate('/checkin/scan',
                            { state: { title: "Voter ID Card", docVal: 'voter-id' } })}
                        sx={{ width: '45%' }}>
                        Scan Voter ID</Button>

                    <Button variant="outlined"
                        onClick={() => navigate('/checkin/scan',
                            { state: { title: "Passport", docVal: 'passport' } })}
                        sx={{ width: '45%' }}>
                        Scan Passport</Button>
                </Grid>

            </Grid>
        </Grid>,
        'fo': <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}><IconButton
                onClick={() => setCurrentScreen('.')}
            ><ArrowBack /></IconButton>Select Authentication Method</Typography>

            <Grid container sx={{ padding: '0 1em' }}>
                <Button variant="outlined"
                    onClick={() => navigate('/checkin/scan',
                        { state: { title: "Passport", docVal: 'passport' } })}>
                    Upload Passport
                </Button>
            </Grid>

        </Grid>,
        'existingAccount': <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ width: '100%' }}>
            <Typography variant="body2" sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                color: '#000000'
            }}>Hey, It looks like you already have an account with us!</Typography>
            <Grid container sx={{ padding: '0 1em' }}>
                {credentials?.length > 0 && credentials?.map((c, i) => <Button
                    variant="outlined"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                    onClick={() => {
                        updateStore('docData', c.credentialAttributes
                            .reduce((acc, x) => {
                                acc[x.name] = x.value
                                return acc;
                            }, {
                                documentName: c?.metadata["_anoncreds/credential"]?.schemaId?.split(":")[2],
                                dontUploadDoc: true
                            }))
                        updateStore("selectedCredentialAttributes", c.credentialAttributes)
                        navigate('/checkin/displayInfo')
                    }}>
                    <span />
                    {c?.metadata["_anoncreds/credential"]?.schemaId?.split(":")[2]}
                    <ArrowForward />
                </Button>)}
                <Button variant="outlined"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                    onClick={() => setCurrentScreen('.')}>
                    <span />
                    Want to upload some other document
                    <ArrowForward />

                </Button>
            </Grid>
        </Grid >,
        'loading': <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ width: '100%' }}>
            <Skeleton variant="rectangular" sx={{
                borderRadius: '6px',
                padding: "1.6em 2em 1.6em 2em",
                margin: '1em 0',
                width: '100%'
            }} />
            <Grid container sx={{ padding: '0 1em' }}>
                {[0, 1, 2]?.map((c, i) => <Skeleton
                    variant="rectangular"
                    sx={{
                        borderRadius: '32px',
                        padding: "1.4em 2em 1.4em 2em",
                        margin: '1em 0',
                        width: '100%'
                    }} />)}
            </Grid>
        </Grid >
    }

    return (
        <><Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: '1em' }}>
            <Card variant="outlined" sx={{ margin: '1em 0', padding: '0 1em 1em', width: '100%' }}>
                {screens[currentScreen]}
            </Card>

            {currentScreen === 'existingAccount' ? <Typography variant="body2" sx={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>Trential Wallet is our Trusted Verification Partner that allows
                you to seamlessly check-in and manage your travel plans.</Typography> : null}

            {loading ? <Backdrop open={loading}>
                <CircularProgress sx={{ position: "absolute", top: '45%', left: '45%' }} color="brand" />
            </Backdrop> : false}
        </Grid>

        </>
    )
}
