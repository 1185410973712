import { createTheme } from "@mui/material/styles";
import { MAX_WIDTH } from "../Utils/enums";

const theme = createTheme({
  palette: {
    white: {
      main: "#ffffff",
      light: "#ffffff",
      contrastText: "#000000"
    },
    primary: {
      main: "#013366",
      light: "#577BCC",
      contrastText: "#fff"
      // light: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#22b40d",
      light: "#22b40d",
      contrastText: "#fff"
      // light: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    grey: {
      main: "#818181",
      light: "#DADADA",
      contrastText: "#fff"
      // light: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    black: {
      main: "#000000",
      light: "#ffffff",
      contrastText: "#fff"
      // light: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    brand: {
      main: "#D3AC67",
      light: "#ffffff",
      contrastText: "#fff"
      // light: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    background: {
      first: "#fff",
      second: "#F6F7FF",
      third: "#DEE4F4",
      fourth: "#eee",
      fifth: "#EEF2FB",
      sixth: "#A9D2EF",
      seventh: "#DFF1FF",
    },
    border: {
      main: "#DADADA",
      dark: "#79747e"
    },
    success: {
      main: "#5CC278",
      bright: "#00CD09"
    },
    pending: {
      main: "#F2CB67"
    },
    error: {
      main: "#FF0000",
      regular: "#D73F3F",
      light: "#FFE5E5"
    },
    // warning: {},
    // info: {},
    contrastThreshold: 3,
    tonalOffset: 0.2,
    trential: {
      main: "#037FFF",
      light: "#037FFF",
      contrastText: "#fff"
      // light: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  spacing: (factor) => `${factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      bsm: 700,
      lsm: 800,
      md: 900,
      bmd: 1000,
      lmd: 1100,
      lg: 1200,
      blg: 1300,
      sxl: 1400,
      xl: 1536,
    },
  },
  overrides: {
    "MuiTooltip-popper": {
      tooltip: {
        fontSize: "42px",
      },
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: ({ ownerState }) => ({
          fontSize: '0.7rem',
          textTransform: 'initial',
          borderRadius: '32px',
          padding: "1em 2em 1em 2em",
          width: '100%',
          maxWidth: MAX_WIDTH+'px',
          minWidth: { xs: '80px', sm: '100px', md: '165px', lg: MAX_WIDTH+'px' },
          ...(ownerState.variant === 'contained' && {
            '&:hover': {
              opacity: 0.7,
            }
          }),
          ...(ownerState.variant === 'outlined' && {
            backgroundColor: '#ffffff',
            margin: '0.6em 0',
            fontWeight: 'bold',
            borderWidth: '2px !important',
            padding: '0.8em'
          }),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: ({ ownerState }) => ({
          borderRadius: '8px',
          textAlign: 'left'
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: '1rem'
        },
      },
    },
    // MuiTypography: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       color: env.theme.text,
    //     },
    //   },
    // },
  },
});

theme.typography.button = {
  textTransform: "none"
};

theme.typography.h6 = {
  color: "#111111",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "1.25rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.24rem",
    lineHeight: "1.5rem"
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.4rem",
    lineHeight: "2rem"
  },
  margin: '1em 0'
};

theme.typography.body2 = {

  fontSize: "0.8rem",
  color: "#8B8686",
  margin: '0.8em 0'
};

export default theme;
