import axios from "axios"
import qs from 'qs'
import { base64ToBinary } from "../Helper/helpers"
import { trentialVerifiedId } from "../Utils/enums"
import { getAccessToken } from "../Utils/tokenUtils"
import { axiosBaseInstance, axiosDigilockerInstance, axiosIssuerTrentialInstance } from "./axios"
import { routes } from "./routes"
import { fetchFromStore, login, updateStore } from "./store"

const ISSUER_APIS = process.env.REACT_APP_ISSUER_APIS
const DIGILOCKER_ENDPOINT = process.env.REACT_APP_DIGILOCKER_ENDPOINT
const ISSUER_API_TOKEN = process.env.REACT_APP_ISSUER_API_TOKEN
const TRENTIAL_ORG_ID = process.env.REACT_APP_TRENTIAL_ORG_ID

const getAxiosHeader = (x) => {

    return ({
        headers: {
            'Authorization': `Bearer ${getAccessToken()}`,
            ...x
        }
    })
}

export const loginCall = async (mobile) => {
    console.log("New change 2")
    return axios.post(process.env.REACT_APP_BASE_URL + routes.login,
        { mobile: mobile })
        .then(res => {
            // if (res?.data?.code === 0) {
            //     // login()
            // }
            return res?.data
        }).catch((e) => {
            console.log(e)
            // window.snackbar('error', e.message)
            console.log(e)
            if (e?.response?.data?.code !== 105) {
                // login()
                window.snackbar('error', e?.response?.data?.message)
            }
            return e?.response?.data
        })
}

export const createWallet = async (name, mobile) => {
    return axiosBaseInstance.post(routes.profile,
        {
            mobile,
            name
        }, getAxiosHeader()).then(res => {

            return res?.data
        }).catch((e) => {
            console.log(e)
            if (e?.response?.data?.code !== 105) {
                // login()
                window.snackbar('error', e?.response?.data?.message)
            }
            return false
        })
}

export const updateWallet = async (name, token) => {
    return axiosBaseInstance.patch(routes.profile,
        {
            name
        }, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then(res => {

        return res?.data
    }).catch((e) => {
        console.log(e)
        if (e?.response?.data?.code !== 105) {
            // login()
            window.snackbar('error', e?.response?.data?.message)
        }
        return false
    })
}

export const otpCall = async (txId, otp) => {
    return axiosBaseInstance.put(routes.otp(txId),
        { otp }, getAxiosHeader()).then(res => {
            if (res?.data?.code === 0) {
                login(res?.data?.data)
                return res?.data?.data
            }
            return false
        }).catch((e) => {
            console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const getWallet = async () => {
    return axiosBaseInstance.get(routes.profile, getAxiosHeader())
        .then(res => {
            if (res?.data?.code === 0) {
                // login()

                console.log('qwqwqwqwqw', res)
                updateStore('currentUserData', res?.data?.data)
            }
            return res.data
        }).catch((e) => {
            // console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const uploadDocGetAttributes = async (image, type) => {
    var formData = new FormData();

    const imageInBinary = await base64ToBinary(image);

    formData.append("file", imageInBinary);

    return axiosBaseInstance.post(routes.uploadDocGetAttributes(type),
        formData, getAxiosHeader({
            'Content-Type': 'multipart/form-data'
        })).then(res => {
            if (res?.data?.code === 0) {
                // login()
                return res?.data?.data.attributes
            }
            return {}
        }).catch((e) => {
            // console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const getCredentials = async () => {
    return axiosBaseInstance.get(routes.credentials, getAxiosHeader())
        .then(res => {

            console.log('filters1', res)
            if (res?.data?.code === 0) {
                // login()
                let trentialId = null
                const filters = res?.data?.data.filter((x) => {
                    console.log(x?.metadata["_anoncreds/credential"]?.schemaId?.split(":")[2] === 'Trential Verified ID')
                    if (x?.metadata["_anoncreds/credential"]?.schemaId?.split(":")[2] === 'Trential Verified ID') {

                        trentialId = x;
                        trentialId.referenceImageUrl = trentialId?.credentialAttributes.find(x => x.name === 'Photo').value
                        return false
                    } else {

                        return true
                    }
                })
                console.log('filters', filters, trentialId)

                updateStore('trentialId', trentialId)
                updateStore('credentials', filters)
                return res?.data?.data?.length > 0
            }
            return false
        }).catch((e) => {
            console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const putVcInWallet = async (type) => {
    return axiosBaseInstance.put(routes.putVcInWallet(type),
        {}, getAxiosHeader())
        .then(res => {
            if (res?.data?.code === 0) {
                return true
            }
            return false
        }).catch((e) => {
            console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const getDigilockerToken = async (code) => {
    return axiosDigilockerInstance
        .get(routes.getDigilockerToken + "?code=" + code,
            getAxiosHeader())
        .then(res => {
            if (res) {
                // login()
                console.log('res digilocker token', res.data)
                updateStore('digilockerAccessToken', res.data?.accessToken)
                // getAadhaar(res.data?.accessToken)
                return res.data?.accessToken
            }
            return false
        }).catch((e) => {
            // console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const sendAadhaarToWallet = async (accessToken) => {
    return axiosBaseInstance.post(routes.getAadhaar,
        { accessToken },
        getAxiosHeader()).then(res => {
            if (res?.data?.code === 0) {
                return res?.data?.data
            }
        }).catch((e) => {
            console.log(e)
            // window.snackbar('error', e.message)
            return e?.response?.data
        })
}

export const getIssuedDocsFromDigilocker = async (accessToken = fetchFromStore('digilockerAccessToken')) => {
    return axiosDigilockerInstance.get(routes.getIssuedDocsFromDigilocker, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        }
    })
        .then(res => {
            if (res) {
                // login()
                updateStore('digilockerCredentials', res.data?.items)
                // getAadhaar(res.data?.accessToken)
                return res.data?.items
            }
            return false
        }).catch((e) => {
            // console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const putVcInWalletFromDigilocker = async (uri) => {
    const accessToken = fetchFromStore('digilockerAccessToken')
    return axiosBaseInstance.post(routes.putVcInWalletFromDigilocker(uri),
        { accessToken }, getAxiosHeader()).then(res => {
            if (res?.data?.code === 0) {
                window.snackbar("success", 'Credential added successfully')
                return res?.data?.data?.attributes
            }
        }).catch((e) => {
            console.log(e)
            window.snackbar('error', e.message)
            return false
        })
}

export const getDlInDigilocker = async (dlno) => {

    const accessToken = fetchFromStore('digilockerAccessToken')
    try {
        const res = await fetch(`${DIGILOCKER_ENDPOINT}${routes.pullDocumentInDigilocker}`, {
            method: 'post',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: qs.stringify({
                orgid: '000048',
                doctype: 'DRVLC',
                consent: 'Y',
                dlno
            }),
        }).then((res) => res.json())
        console.log('res', res)
        return res
    } catch (err) {
        window.snackbar('error', err.message)
        return false
    }
}


export const preCheckIn = async (aadhaarData, referenceImageUrl, identifier, name) => {
    const user = await getWallet();
    console.log('user==', user)
    try {
        const res = await axios({
            method: 'POST',
            url: 'https://access-checkin-dev-7kuqnnxqla-el.a.run.app/api/1.0/entries',
            // url: `${ACCESS_CONTROL_API_ENDPOINT}${routes.accessControl.entries}`,
            data: {
                identifier,
                name: name ?? "-",
                email: user?.data?.email ?? "itc-user@email.com",
                mobile: user?.data?.mobile ?? "-",
                type: "visitor",
                purpose: "Check In",
                data: {
                    aadhaarData,
                    preCheckinImageUrl: referenceImageUrl
                }
            }
        })
        console.log('preCheckIn response', res?.data?.data)
        return {
            error: false,
            statusCode: 200,
            data: res?.data?.data,
        }
    }
    catch (e) {
        console.log('preCheckIn Error', JSON.stringify(e))
        return {
            error: true,
            statusCode: e?.response?.status,
            errorMessage: e?.response?.data?.message,
        }
    }
}

export const getCredentialInvitation = async (
    attributes,
    candidateIdentifier,
    img
) => {
    const credentialBatchId = trentialVerifiedId.credentialBatchId


    const atrs = [];
    trentialVerifiedId.attributes.forEach((x) => {
        atrs.push({ name: x.name, value: attributes[x.mapping[attributes.documentName]] })
    })
    atrs.push({ name: 'Photo', value: img })
    try {
        const res = await axios({
            method: 'POST',
            url: `${ISSUER_APIS}${routes.getCredentialInvitation}/${credentialBatchId}/invitation`,
            headers: {
                Authorization: `Bearer ${ISSUER_API_TOKEN}`,
                orguid: TRENTIAL_ORG_ID,
            },
            data: {
                attributes: atrs,
                candidateIdentifier,
            },
        })
        console.log('getCredentialInvitation response', res?.data?.data)
        return {
            error: false,
            statusCode: 200,
            data: res?.data?.data,
        }
    } catch (e) {
        console.log('getCredentialInvitation Error', JSON.stringify(e))
        return {
            error: true,
            statusCode: e?.response?.status,
            errorMessage: e?.response?.data?.message,
        }
    }
}

export const receieveOob = async (oobInvitation) => {
    return axiosBaseInstance.post(routes.oobInvitation,
        {
            oobInvitation
        }, getAxiosHeader()).then(res => {
            if (res?.data?.code === 0) {
                // login()
            }
            console.log(res)
            return true
        }).catch((e) => {
            // console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const startTrentialIdCreation = async (attrs, img) => {

    try {
        const user = await getWallet();
        const res = await getCredentialInvitation(attrs, user?.data.mobile, img)
        if (!res.error) {
            return receieveOob(res.data.invitationUrl)
        }
        return false;
    } catch (e) {
        console.log(e)
        return false
    }
}


export const aadharCall = async (an) => {
    return axiosIssuerTrentialInstance.post(routes.aadharCall,
        { aadhaarNumber: an }).then(res => {
            if (res?.data?.code === 0) {
                return res?.data?.data?.ref_id
            }
            return false
        }).catch((e) => {
            console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}

export const aadhaarOtpCall = async (refId, otp, navigate) => {
    return axiosIssuerTrentialInstance
        .post(routes.aadhaarOtpCall,
            {
                otp,
                refId
            })
        .then(async (res) => {
            if (res?.data?.code === 0) {
                let attributes = res?.data?.data?.attributes

                let obj = {}
                if (attributes) {

                    attributes?.forEach((x) => {
                        obj[x.name] = x.value
                    })

                    updateStore('docData', {
                        docVal: "Aadhaar Card",
                        documentName: "Aadhaar",
                        invitationUrl: res?.data?.data?.invitationUrl,
                        dontUploadDoc: 'oob',
                        ...obj
                    })

                    updateStore('selectedCredentialAttributes', attributes)
                    navigate('/checkin/displayInfo', { state: { showLoading: false } })
                }

            } else {
                // window.snackbar('error', 'Trential ID creation failed')
                navigate("/")
            }
            return false
        }
        ).catch((e) => {
            console.log(e)
            // window.snackbar('error', e.message)
            return e?.response?.data
        })
}

export const masterDigilocker = async (uri, item, navigate) => {
    const accessToken = fetchFromStore('digilockerAccessToken')
    return axiosIssuerTrentialInstance
        .get(routes.masterDigilocker + '/' + uri, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        })
        .then(async (res) => {
            if (res?.data?.code === 0) {
                let attributes = res?.data?.data?.attributes

                let obj = {}
                if (attributes) {

                    attributes?.forEach((x) => {
                        obj[x.name] = x.value
                    })

                    updateStore('docData', {
                        docVal: item,
                        documentName: item,
                        invitationUrl: res?.data?.data?.invitationUrl,
                        dontUploadDoc: 'oob',
                        ...obj
                    })

                    updateStore('selectedCredentialAttributes', attributes)
                    navigate('/checkin/displayInfo', { state: { showLoading: false } })
                }

            } else {
                // window.snackbar('error', 'Trential ID creation failed')
                navigate("/")
            }
            return false
        }
        ).catch((e) => {
            console.log(e)
            // window.snackbar('error', e.message)
            return false
        })
}